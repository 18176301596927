import {map, Observable} from "rxjs";
import {Sendout, SendoutProcessorsConfig, SendoutSource, SendoutState} from "../models/sendout";
import axios from "axios-observable";
import {CONFIG} from "../config";
import {Page} from "../models/page";
import {ContentDebuggingResult} from "../models/content-debugging-result";
import {ProductDescriptor} from "../models/product-descriptor";
import {Status} from "../models/status";

export namespace SendoutsApi {

    export function createSendout(contentId: string, title: string, campaignId: number): Observable<Sendout> {
        console.log("Creating a sendout");
        return axios.post(`${CONFIG.apiHost}api/sendouts/`, {contentId, title, campaignId}, {withCredentials: true}).pipe(
            map(response => Sendout.parse(response.data)),
        );
    }

    export function getSendouts(
        page: number,
        pageSize: number,
        stateFilter: SendoutState | null,
        productFilter: ProductDescriptor | null,
        onlyOwnSendouts: boolean,
    ): Observable<Page<Sendout>> {
        console.log(`Loading sendouts (state filter: ${stateFilter})`);
        return axios.get(
            `${CONFIG.apiHost}api/sendouts/`,
            {
                params: {
                    page,
                    pageSize,
                    state: stateFilter ?? undefined,
                    ownOnly: onlyOwnSendouts || undefined,
                    product: productFilter?.value || undefined,
                },
                withCredentials: true
            },
        ).pipe(
            map(response => {
                const page = response.data as Page<any>;
                page.content = page.content.map(e => Sendout.parse(e));
                return page;
            }),
        );
    }

    export function cancelScheduledSendout(sendoutId: string): Observable<any> {
        console.log(`Cancelling scheduled sendout with id=${sendoutId}`);
        return axios.post(`${CONFIG.apiHost}api/sendouts/${sendoutId}/cancel`, null, {withCredentials: true}).pipe();
    }

    export function copySendout(sendoutId: string): Observable<Sendout> {
        console.log(`Duplicating sendout with id=${sendoutId}`);
        return axios.post(`${CONFIG.apiHost}api/sendouts/${sendoutId}/copy`, null, {withCredentials: true}).pipe(
            map(response => Sendout.parse(response.data)),
        );
    }

    export function getSendout(id: string): Observable<Sendout> {
        console.log(`Loading the sendout with id=${id}`);
        return axios.get(`${CONFIG.apiHost}api/sendouts/${id}`, {withCredentials: true}).pipe(
            map(response => Sendout.parse(response.data)),
        );
    }

    export function runSendout(id: string, ignorePauseInterval: boolean, date: Date | null): Observable<any> {
        console.log(`Running the sendout with id=${id}`);
        return axios.post(`${CONFIG.apiHost}api/sendouts/${id}/schedule`, {ignorePauseInterval, date}, {withCredentials: true}).pipe();
    }

    export function patchSendout(id: string, request: PatchSendoutRequest): Observable<Sendout> {
        console.log(`Patching sendout with id=${id}`);
        return axios.patch(`${CONFIG.apiHost}api/sendouts/${id}`, request, {withCredentials: true}).pipe(
            map(response => Sendout.parse(response.data)),
        );
    }

    // only DRAFT sendouts may be deleted
    export function deleteSendout(id: string): Observable<any> {
        console.log(`Deleting the sendout with id=${id}`);
        return axios.delete(`${CONFIG.apiHost}api/sendouts/${id}`, {withCredentials: true}).pipe();
    }

    export function sendSample(id: string, customEmail: string | null): Observable<any> {
        console.log(`Sending a sample email for the sendout with id=${id}`);
        return axios.post(`${CONFIG.apiHost}api/sendouts/${id}/sample`, {customEmail}, {withCredentials: true}).pipe();
    }

    export function getRecentSendoutsCount(): Observable<number> {
        console.log("Loading a count of recent sendouts");
        return axios.get(`${CONFIG.apiHost}api/sendouts/recent`, {withCredentials: true}).pipe(
            map(response => response.data.count),
        );
    }

    export function debugContent(sendoutId: string): Observable<ContentDebuggingResult> {
        console.log(`Loading debug data for sendout with id=${sendoutId}`);
        return axios.get(`${CONFIG.apiHost}api/sendouts/${sendoutId}/debug-content`, {withCredentials: true}).pipe(
            map(response => response.data as ContentDebuggingResult),
        );
    }

    export function getOverallStatus(): Observable<Status> {
        console.log(`Loading overall status`);
        return axios.get(`${CONFIG.apiHost}api/sendouts/status`, {withCredentials: true}).pipe(
            map(response => Status.parse(response.data)),
        );
    }

    export function markAsAborted(sendoutId: string): Observable<any> {
        console.log(`Marking sendout ${sendoutId} as manually aborted via Marketo's UI`);
        return axios.patch(`${CONFIG.apiHost}api/sendouts/${sendoutId}/mark-as-aborted`, {}, {withCredentials: true});
    }

    export function reloadSendoutContentFromBlog(sendoutId: string): Observable<Sendout> {
        console.log(`Reloading sendout's content from the blog for the sendout with id=${sendoutId}`);
        return axios.post(`${CONFIG.apiHost}api/sendouts/${sendoutId}/reload`, {}, {withCredentials: true}).pipe(
            map(response => Sendout.parse(response.data)),
        );
    }

    export function autofixSendout(sendoutId: string): Observable<Sendout> {
        console.log(`Trying to automatically fix the sendout with id=${sendoutId}`);
        return axios.post(`${CONFIG.apiHost}api/sendouts/${sendoutId}/autofix`, null, {withCredentials: true}).pipe(
            map(response => Sendout.parse(response.data)),
        );
    }

    export interface PatchSendoutRequest {
        targetCampaignId?: number;
        title?: string;
        creatorName?: string;
        preheader?: string;
        processedHtml?: string;
        rawHtml?: string;
        processorsConfig?: {
            useDefault: boolean;
            customValues?: SendoutProcessorsConfig;
        };
        forcedFeaturedImage?: boolean;
    }

}

export interface ScheduleSendoutRequest {
    ignorePauseInterval?: boolean;
}
