import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Issue = styled.div`
  margin: 10px 0;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  & p {
    margin: 0;
  }
`;

export const CheckButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: lightblue;
  &:hover, &:disabled {
    color: limegreen;
  }
`;

export const Warning = styled.p<{show: boolean}>`
  font-size: 0.9em;
  color: darkred;
  text-align: center;
  visibility: ${props => props.show ? "visible" : "hidden"};
`;

export const TypoWord = styled.span`
  background-color: darkred;
  font-style: italic;
  text-decoration: line-through;
  padding: 0 6px;
`;

export const FixedWord = styled.span`
  background: #4d804d;
  font-style: italic;
  font-weight: 600;
  padding: 0 6px;
`;
