import React, {useState} from "react";
import * as S from "./styles";
import {Sendout as SendoutModel, SendoutState} from "../../models/sendout";
import {sendoutsActions} from "../../redux/slices/sendouts-slice";
import defaultImg from "./placeholder.png";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import confirm from "@jetbrains/ring-ui/dist/confirm-service/confirm-service";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {Modal, ModalWidth} from "../Modal/Modal";
import {unescapeString} from "../../utils/utils";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import {IconType} from "react-icons";
import {FaRegCopy, FaRegEdit} from "react-icons/fa";
import {MdDelete, MdOutlineCancel, MdOutlinePreview} from "react-icons/md";
import {TbCalendarCancel} from "react-icons/tb";
import {RiMailSendLine} from "react-icons/ri";
import {VscDebug} from "react-icons/vsc";
import {Icon} from "../Icon/Icon";
import {ImStatsDots} from "react-icons/im";

interface Props {
    sendout: SendoutModel;
    newUi: boolean;
}

interface Action {
    fn(): void;
    label: string;
    primary?: boolean;
    danger?: boolean;
    icon: IconType;
}

export const Sendout: React.FC<Props> = (props) => {

    const {sendout, newUi} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showStacktrace, setShowStacktrace] = useState(false);
    const mauUser = useAppSelector(state => state.users.currentUserInfo?.isAdmin ?? false);
    const marketoConfig = useAppSelector(state => state.config.marketoConfig);

    const cancel = () => {
        dispatch(sendoutsActions.cancelScheduledSendout({sendoutId: sendout.id}));
    };

    const forceSend = () => {
        confirm({
            text: "Run this sendout?",
            description: "This sendout is scheduled to be sent later. Are you sure you want to send it immediately?",
        }).then(() => dispatch(sendoutsActions.runSendout({sendoutId: sendout.id, ignorePauseInterval: true})));
    };

    const sentRecently = sendout.state === "SENT" && ((Date.now() - sendout.sentAt!.getTime()) / 1000 / 60 < 60);

    const availableActions: Action[] = [
        {
            label: "Copy",
            fn: () => dispatch(sendoutsActions.copySendout({sendoutId: sendout.id})),
            icon: FaRegCopy,
        },
        {
            label: "Open preview",
            fn: () => window.open(`/preview/${sendout.id}`, "_blank"),
            icon: MdOutlinePreview,
        }
    ];
    if (sendout.state === "DRAFT") {
        availableActions.unshift({
            label: "Edit",
            fn: () => navigate(`/editor/${sendout.id}`),
            primary: true,
            icon: FaRegEdit,
        });
        availableActions.push({
            label: "Delete",
            fn: () => {
                confirm({
                    text: "Delete the sendout?",
                    description: "You'll lost all changes you made during a sendout customization. Of course " +
                        "you still can create a new sendout for the same blog-post on the Feed page.",
                }).then(() => dispatch(sendoutsActions.deleteSendout({sendoutId: sendout.id})))
                    .catch(() => {});
            },
            danger: true,
            icon: MdDelete,
        });
    } else if (sendout.state === "SCHEDULED") {
        availableActions.push({
            label: "Send now anyway",
            fn: forceSend,
            danger: true,
            icon: RiMailSendLine,
        });
        availableActions.push({
            label: "Cancel",
            fn: cancel,
            danger: true,
            icon: TbCalendarCancel,
        });
    } else if (sendout.state === "FAILED" && sendout.errorDescription !== null) {
        availableActions.push({
            label: "Show stacktrace",
            fn: () => setShowStacktrace(true),
            icon: VscDebug,
        })
    } else if (sendout.state === "SENT" && mauUser) {
        availableActions.push({
            label: "Show stats",
            fn: openStats,
            icon: ImStatsDots,
        });
        availableActions.push({
            label: "Mark as aborted (admin only; won't stop the sendout)",
            fn: () => {
                confirm({
                    text: "Mark as aborted?",
                    description: "This sendout will be displayed as manually aborted. Please note that it will not " +
                        "change anything on the Marketo side, so make sure that the corresponding Marketo campaign " +
                        "was actually stopped."
                }).then(() => dispatch(sendoutsActions.markAsAborted({sendoutId: sendout.id})))
                    .catch(() => {});
            },
            danger: true,
            icon: MdOutlineCancel,
        });
    }

    const campaignName = marketoConfig?.products?.flatMap(p => p.campaigns)?.find(c => c.id === sendout.targetCampaignId)?.name ?? "[unknown]";

    function openStats(): void {
        const url = new URL("https://mari.labs.jb.gg/abm/pa/");
        url.searchParams.append("wordpress_id", sendout.contentId);
        window.open(url.toString(), "_blank");
    }

    return <>
        {newUi
            ? <S.Card>
                <S.Image src={sendout.image ?? defaultImg} grayscale={sendout.state !== "SCHEDULED" && sendout.state !== "SENT"}/>
                <S.Line background={resolveBadgeStyles(sendout.state).backgroundColor}>{sendout.state}</S.Line>
                <S.DataContainer>
                    <S.Title>{sendout.title}</S.Title>
                    <S.Meta><b>Campaign:</b> {campaignName}</S.Meta>
                    <S.Meta><b>Created</b> at {sendout.createdAt.toLocaleString("ru-RU")} by {sendout.creatorEmail ?? "unknown creator"}</S.Meta>
                    {sendout.scheduledFor && !sendout.sentAt && <S.Meta><b>Scheduled</b> to be sent at {sendout.scheduledFor.toLocaleString("ru-RU")} by {sendout.triggeredBy ?? "unknown sender"}</S.Meta>}
                    {sendout.sentAt && <S.Meta><b>Sent</b> at {sendout.sentAt.toLocaleString("ru-RU")} by {sendout.triggeredBy ?? "unknown sender"}</S.Meta>}
                </S.DataContainer>
                <S.ButtonsContainer>
                    {availableActions.length > 0 && availableActions.map((action, idx) => {
                        if (idx === 0)
                            return <S.Button onClick={action.fn} key={idx}>
                                <Icon Type={action.icon}/> {action.label}
                            </S.Button>;
                        else {
                            return <Tooltip title={action.label} key={idx}>
                                <S.IconButton
                                    onClick={action.fn}
                                    mainColor={action.danger ? "#ff6767" : "#AAA"}
                                    hoverColor={action.danger ? "#F64C49" : "#777"}
                                >
                                    <Icon Type={action.icon}/>
                                </S.IconButton>
                            </Tooltip>;
                        }
                    })}
                </S.ButtonsContainer>
            </S.Card>
            : <S.ListItem key={sendout.id} recent={sendout.state === "SCHEDULED" || sentRecently}>
                <div style={{display: "flex", flexFlow: "row nowrap", gap: "10px", alignItems: "center"}}>
                    <img src={sendout.image ?? defaultImg} className="sendout-img"/>
                    <S.SendoutInfoContainer>
                        <S.MetadataContainer>
                            <p>{unescapeString(sendout.title)}</p>
                            <S.SendoutMetadata><b>Campaign:</b> {campaignName}</S.SendoutMetadata>
                            <S.SendoutMetadata><b>Created</b> at {sendout.createdAt.toLocaleString("ru-RU")} by {sendout.creatorEmail ?? "unknown creator"}</S.SendoutMetadata>
                            {sendout.scheduledFor && <S.SendoutMetadata><b>Scheduled</b> to be sent at {sendout.scheduledFor.toLocaleString("ru-RU")} by {sendout.triggeredBy ?? "unknown sender"}</S.SendoutMetadata>}
                            {sendout.sentAt && <S.SendoutMetadata><b>Sent</b> at {sendout.sentAt.toLocaleString("ru-RU")} by {sendout.triggeredBy ?? "unknown sender"}</S.SendoutMetadata>}
                        </S.MetadataContainer>
                        <S.Badge style={{...resolveBadgeStyles(sendout.state), color: "black", border: "none"}}>{sendout.state}</S.Badge>
                    </S.SendoutInfoContainer>
                </div>
                {availableActions.length > 0 && <div className="sendout-actions-block">
                    {availableActions.map((action, idx) => <Button
                        onClick={action.fn}
                        key={idx}
                        disabled={action.fn == null}
                        primary={action.primary}
                        danger={action.danger}
                    >
                        {action.label}
                    </Button>)}
                </div>}
            </S.ListItem>}

        <Modal show={showStacktrace} title="Error stacktrace" onClose={() => setShowStacktrace(false)} modalWidth={ModalWidth.WIDE}>
            <div style={{fontSize: "0.8em", maxHeight: "80vh", overflow: "auto"}}>
                {sendout.errorDescription}
            </div>
        </Modal>
    </>;

};

function resolveBadgeStyles(state: SendoutState) {
    switch (state) {
        case "ABORTED":
        case "BLOCKED":
        case "CANCELLED":
        case "FAILED":
            return {backgroundColor: "rgb(241,142,99)"};
        case "DRAFT":
            return {backgroundColor: "rgb(208,208,208)"};
        case "SCHEDULED":
            return {backgroundColor: "rgb(156,235,253)"};
        case "SENT":
            return {backgroundColor: "rgb(192,253,156)"};
        default:
            throw Error("Unexpected sendout state: " + state);
    }
}
