import {combineEpics, Epic, ofType} from "redux-observable";
import {editorActions, PatchSendoutActionPayload} from "../slices/editor-slice";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {PayloadAction} from "@reduxjs/toolkit";
import {SendoutsApi} from "../../api/sendouts-api";
import {Sendout} from "../../models/sendout";
import {miscActions} from "../slices/misc-slice";
import {CONFIG} from "../../config";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {sendoutsActions} from "../slices/sendouts-slice";

const loadSendout$: Epic = (action$) => action$.pipe(
    ofType(editorActions.loadSendout),
    switchMap((action: PayloadAction<string>) => SendoutsApi.getSendout(action.payload).pipe(
        map(sendout => editorActions.onSendoutLoaded(sendout)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const patchSendout$: Epic = (action$) => action$.pipe(
    ofType(editorActions.patchSendout),
    switchMap((action: PayloadAction<PatchSendoutActionPayload>) => SendoutsApi.patchSendout(action.payload.sendoutId, action.payload.request).pipe(
        map(sendout => editorActions.onSendoutPatched(sendout)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSendoutPatched$: Epic = (action$) => action$.pipe(
    ofType(editorActions.onSendoutPatched),
    map((action: PayloadAction<Sendout>) => editorActions.onSendoutLoaded(action.payload)),
    tap(() => {
        alertService.successMessage("Changes have been saved", CONFIG.defaultAlertsTimeoutMs);
    }),
);

const reloadSendoutContentFromBlog$: Epic = (action$) => action$.pipe(
    ofType(editorActions.reloadSendoutContentFromBlog),
    switchMap((action: PayloadAction<string>) => SendoutsApi.reloadSendoutContentFromBlog(action.payload).pipe(
        map(sendout => editorActions.onSendoutPatched(sendout)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const autofixSendout$: Epic = (action$) => action$.pipe(
    ofType(editorActions.autofixSendout),
    switchMap((action: PayloadAction<string>) => SendoutsApi.autofixSendout(action.payload).pipe(
        map(sendout => editorActions.onSendoutAutofixed(sendout)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSendoutAutofixed$: Epic = (action$) => action$.pipe(
    ofType(editorActions.onSendoutAutofixed),
    map((action: PayloadAction<Sendout>) => editorActions.onSendoutPatched(action.payload)),
);

export const editorEpics = combineEpics(
    loadSendout$,
    patchSendout$,
    onSendoutPatched$,
    reloadSendoutContentFromBlog$,
    autofixSendout$,
    onSendoutAutofixed$,
);
