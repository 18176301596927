import styled, {keyframes} from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  padding: 12px 18px;
  margin: 2px auto;
  border-radius: 24px;
  min-width: 200px;
  background: rgb(17,113,190);
  background: linear-gradient(90deg, rgba(17,113,190,1) 0%, rgba(26,148,246,1) 100%);
  transition: all ease-in-out 150ms;
  font-weight: 600;
  
  &:disabled {
    background: rgb(100, 100, 100) !important;
    cursor: initial !important;
  }
  
  &:hover:not(:disabled) {
    transform: scale(1.05);
  }
  
  &.magic {
    background: linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(118,87,209,1) 44%, rgba(252,70,107,1) 100%);
  }
  
  .spinning {
    animation: ${spin} 1s linear infinite;
  }
`;
