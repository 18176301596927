import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {SendoutIssue, TypoIssuePayload} from "../../models/sendout-issue";
import {Icon} from "../Icon/Icon";
import {BiError, BiErrorCircle} from "react-icons/bi";
import {BsCheckSquareFill} from "react-icons/bs";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import {RiErrorWarningLine} from "react-icons/ri";
import {useAppDispatch} from "../../redux/store";
import {editorActions} from "../../redux/slices/editor-slice";
import {SiCodemagic} from "react-icons/si";
import {JetblogButton} from "../JetblogButton/JetblogButton";

interface Props {
    issues: SendoutIssue[];
    onIssuesStatusChanged(resolved: boolean): void;
    sendoutId: string;
}

interface CheckableIssue {
    issue: SendoutIssue;
    checked: boolean;
}

type BrokenTwitterLinksIssuePayload = {link: string, label: string}[];

export const SendoutIssuesList: React.FC<Props> = (props) => {

    const onIssuesStatusChanged = props.onIssuesStatusChanged;
    const sendoutId = props.sendoutId;
    const dispatch = useAppDispatch();
    const [issues, updateIssues] = useState<CheckableIssue[]>([]);
    const [fixing, setFixing] = useState(false);

    // all BROKEN_LINK issues related to Twitter will be merged in a single issue:
    useEffect(() => {
        const _issues = props.issues.reduce((acc, curr) => {
            if (curr.type === "BROKEN_LINK" && curr.name.startsWith("https://twitter.com")) {
                const mergedIssue = acc.find(i => i.type === "BROKEN_TWITTER_LINK") ?? new SendoutIssue(
                    "BROKEN_TWITTER_LINK",
                    "Potentially broken Twitter links. JetBlog is unable to check them automatically, so please do it manually:",
                    JSON.stringify([] as BrokenTwitterLinksIssuePayload),
                    "WARNING",
                );
                const currentTwitterLinks: BrokenTwitterLinksIssuePayload[] = JSON.parse(mergedIssue.description!);
                return [...acc.filter(i => i.type !== "BROKEN_TWITTER_LINK"), mergedIssue.copy({
                    description: JSON.stringify([
                        ...currentTwitterLinks,
                        {link: curr.name, label: curr.description},
                    ] as BrokenTwitterLinksIssuePayload),
                })];
            } else {
                return [...acc, curr];
            }
        }, [] as SendoutIssue[]);
        updateIssues(_issues.map(issue => ({issue, checked: false})));
    }, [props.issues]);

    useEffect(() => {
        onIssuesStatusChanged(issues.every(i => i.checked));
        setFixing(false);
    }, [issues]);

    const markAsChecked = (issue: SendoutIssue) => {
        updateIssues(issues => issues.map(i => i.issue === issue ? ({...i, checked: true}) : i));
    };

    if (issues.length === 0) {
        return null;
    }

    function autofix(): void {
        setFixing(true);
        dispatch(editorActions.autofixSendout(sendoutId));
    }

    return <S.Container>
        <p style={{color: "#777", margin: 0, fontSize: "0.8em"}}>Issues:</p>
        {issues.map(({issue, checked}, idx) => {
            const icon = issue.severity === "ERROR"
                ? <Icon Type={BiErrorCircle} style={{color: "red"}}/>
                : <Icon Type={BiError} style={{color: "#edb013"}}/>;
            let content = null;
            switch (issue.type) {
                case "BROKEN_LINK": {
                    content = <p>Link "{issue.description}" → <a href={issue.name} style={{wordBreak: "break-all"}} target="_blank" rel="noreferrer">{issue.name}</a> seems to be broken</p>;
                    break;
                }
                case "BROKEN_TWITTER_LINK": {
                    const payload = JSON.parse(issue.description!) as BrokenTwitterLinksIssuePayload;
                    content = <div>
                        <p>{issue.name}</p>
                        {payload.map((item, idx) => <p key={idx}><a href={item.link}>{item.label}</a></p>)}
                    </div>;
                    break;
                }
                case "TYPO": {
                    const payload = JSON.parse(issue.description!) as TypoIssuePayload;
                    const typo = payload.wordWithTypo;
                    const sentenceParts = payload.originalSentence.split(new RegExp(`(${typo})`, "gi"));
                    content = <div>
                        <p>
                            <b>{issue.name}: </b>
                            {sentenceParts.map((part, idx) => part.toLowerCase() === typo.toLowerCase()
                                ? <Tooltip title={payload.explanation}>
                                    <S.TypoWord key={idx}>{part}</S.TypoWord><S.FixedWord key={idx}>{payload.fixedWord}</S.FixedWord>
                                  </Tooltip>
                                : part
                            )}
                        </p>
                    </div>;
                    break;
                }
                default: {
                    content = <p>{issue.name} ({issue.description})</p>;
                }
            }
            return <S.Issue key={idx}>
                {icon}
                {content}
                {issue.severity === "WARNING" && <Tooltip title={checked ? "Marked as checked" : "Mark this issues as checked"}>
                    <S.CheckButton disabled={checked} onClick={() => markAsChecked(issue)}>
                        <BsCheckSquareFill style={{transform: "translateY(3px)"}}/>
                    </S.CheckButton>
                </Tooltip>}
            </S.Issue>;
        })}
        <S.Warning show={issues.some(i => !i.checked)}>
            <Icon style={{color: "darkred"}} Type={RiErrorWarningLine}/> There are still some unresolved issues
        </S.Warning>
        {issues.some(i => i.issue.type === "TYPO") && <JetblogButton
            onClick={autofix}
            loading={fixing}
            variant="magic-gradient"
            display="block"
        >
            <Icon Type={SiCodemagic}/> Autofix all typos
        </JetblogButton>}
    </S.Container>;

};
